// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    auth: {
      cognitoClientId: '1ee08lu04huuiu6t3455eumi46',
      cognitoUserPoolId: 'ap-southeast-2_pgErjyL4O',
      scopes: 'openid profile https://uoa-tv.auckland.ac.nz/uoa-tv-spa',
      cognitoAwsRegion: 'ap-southeast-2',
      cognitoDomain: 'uoapool-sandbox',
      redirectUri: 'https://uoatv.dev.auckland.ac.nz',
      logoutUri: 'https://uoatv.dev.auckland.ac.nz',
  
      /**
       * When you create appClient, select `https://test-domain.auckland.ac.nz/lambda-hello-world` under customscopes
       */
      codeChallengeMethod: 'S256',
    },
    privateUrlKeyWords: {
      // default optional to false
      whoNeedBearerToken: [{ url: 'apigw.sandbox.amazon.auckland.ac.nz', optional: false }],
    },
    apiServer: 'https://apigw.sandbox.amazon.auckland.ac.nz/uoa-tv/'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  