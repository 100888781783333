import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard, LoginSuccessGuard } from '@uoa/auth';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'category',
    pathMatch: 'full',
  },
  {
    path: 'error/:errorCode',
    loadChildren: () => import('./error-routing/error-routing.module').then((m) => m.ErrorRoutingModule),
  },
  {
    path: 'home',
    canActivate: [LoginSuccessGuard],
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/category-list/category-list.module').then((m) => m.CategoryListPageModule),
  },
  {
    path: 'media/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/media-player/media-player.module').then((m) => m.MediaPlayerPageModule),
  },
  {
    path: 'protected',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/protected/protected.module').then((m) => m.ProtectedPageModule),
  },
  {
    path: '**',
    redirectTo: '/category',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
