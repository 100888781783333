import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private _meta: Meta) {}

  addMetaToPage(description: string) {
    const metaDescription = this._meta.getTag('name=description');
    console.log('metaDescription', metaDescription);
    if (metaDescription) {
      this._meta.updateTag({
        name: 'description',
        content: description,
      });
    } else {
      this._meta.addTag({
        name: 'description',
        content: description,
      });
    }
  }
}
